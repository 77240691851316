<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<a-tree-select v-model="corp_id" @change="corpChange" style="width: 300px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{ children: 'list', title: 'corp_name', key: 'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="日期查询">
					<a-range-picker @change="onChange" :disabledDate="disabledDate" style="width:240px;">
						<a-icon slot="suffixIcon" type="smile" />
					</a-range-picker>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="download(record.file_url)">
						下载
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.efs_id)">
						<template slot="title">
							<p>是否确定删除该日结记录导出？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">				
				<div style="float: left;">注：请于自动日结时间延迟十分钟下载。</div>
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>
	</div>
</template>

<script>
import http from '@/http'
import moment from 'moment'

const columns = [{
	title: '文件名称',
	dataIndex: 'file_name',
	key: 'file_name',
},
{
	title: '生成时间',
	key: 'add_time',
	dataIndex: 'add_time',
},
{
	title: '操作',
	key: 'age6',
	width: 250,
	scopedSlots: {
		customRender: 'age6'
	},
},
];
export default {
	data() {
		return {
			columns,
			start_date: '',
			end_date: '',
			corp_id: null,
			tableData: [],
			tableTotal: 0,
			tablePage: 1,
			corpList: [],
			corpTreeList: [],
			defaultCorp: [],
		};
	},
	mounted() {
		http({
			method: 'post',
			url: '/api.corp.treelist/',
			data: {}
		}).then((res) => {
			this.corpList = res.data
			let corpTreeList = {}
			function forData(data, ids) {
				data.forEach((v) => {
					corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
					if (v.list.length) {
						forData(v.list, [...ids, v.corp_id])
					}
				})
			}
			res.data.forEach((v, i) => {
				corpTreeList[v.corp_name + v.corp_id] = v.corp_id
				if (v.list.length) {
					forData(v.list, [v.corp_id])
				}
				if (i == 0) {
					let defaultCorp = { ...corpTreeList }
					let keys = Object.keys(defaultCorp)
					this.defaultCorp = v.corp_id
				}
			})
			this.corpTreeList = corpTreeList
			this.corp_id = this.defaultCorp
			this.getTable()
		})
	},
	methods: {
		disabledDate (current) {
			return current && current > moment().endOf('day')
		},
		onChange(data) {
			this.start_date = moment(data[0]).format('YYYY-MM-DD')
			this.end_date = moment(data[1]).format('YYYY-MM-DD')
		},	
		corpChange(data) {
			this.corp_id = data
		},
		btnClick(type) {
			if (type == '查询') {
				this.getTable()
			} else if (type == '重置') {
				this.corp_id = this.defaultCorp
				this.start_date = ''
				this.end_date = ''
				this.tablePage = 1
				this.getTable()
			} else if (type == '刷新') {
				this.getTable()
			}
		},
		getTable() {
			http({
				method: 'post',
				url: '/api.deposit.export.download/',
				data: {
					page: this.tablePage,
					start_date: this.start_date,
					end_date: this.end_date,
					corp_id: this.corp_id
				}
			}).then((res) => {
				this.tableData = res.data.list
				this.tableTotal = res.data.total_count
			})
		},
		tabelPageChange(page) {
			this.tablePage = page
			this.getTable()
		},
		remove(id) {
			http({
				method: 'post',
				url: '/api.account.datarecord.delexport/',
				data: {
					efs_id: id,
				}
			}).then((res) => {
				if (res.code == "200") {
					this.$message.success('删除日结记录导出成功');
					this.getTable()
				} else {
					this.$message.warning(res.message);
				}
			})
		},
		download(file_url) {
            window.open(file_url)
		},
	},
};
</script>

<style lang="less" scoped>
.table-pagination {
	text-align: right;
	padding: 20px 0px;
}

::v-deep .ant-table-body {
	margin: 0;
}

.filter-box {
	padding: 20px;
}

.btns10 {
	margin-left: 30px;

	button {
		margin-right: 10px;
	}
}

.table-box {
	padding: 0px 20px;
}
</style>
